
import React, { PropsWithChildren, ReactElement, ReactNode } from "react"
import styled from "styled-components";
import twitterImg from "../assets/images/twitter.png"
import discoverImg from "../assets/images/discover.png"
import jinImg from "../assets/images/jin.png"
export const FooterWidget: React.FC<PropsWithChildren> = () => {
    return (
        <FooterStyle  className="app_inner">
            <a href="https://twitter.com/rootswapxyz" target="_blank"><img src={twitterImg} alt="" /></a>
            <a href="https://discord.gg/p2KhYjvawk" target="_blank"><img src={discoverImg} alt="" /></a>
            <a href="https://mirror.xyz/rootswap.eth" target="_blank"><img src={jinImg} alt="" /></a>

        </FooterStyle>
    )
}


const FooterStyle = styled.div`
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: center;
    margin-top: 100px;
    img{
        display: block;
        margin-right: 30px;
    }
    img::last-child{
        margin-right: 30px;
    }
    @media (max-width: 1000px) {
        margin-top: 50px;
        img{
            width: 30px;
            height: 30px;
        }
    }
    @media (max-width: 600px) {
        img{
            width: 30px;
            height: 30px;
        }
    }

`