
import React, { PropsWithChildren, ReactElement, ReactNode } from "react"
import styled from "styled-components";
import bannerLogo from "../assets/images/banner_logo.png"
import tvTopLogo from "../assets/images/tv_top.png"
const rootswap_gif = require("../assets/mp4/tv.mp4");

export const BannerWidget: React.FC<PropsWithChildren> = () => {
    return (
        <MainStyle  className="app_inner">

            <div className="inner">
                <div className="video_phone">
                    <video style={{
                        width: "100%"
                    }} loop autoPlay muted playsInline>
                        <source src={rootswap_gif} />
                    </video>
                </div>
                <div className='text'>
                    <img className='logo' src={bannerLogo} alt="" />
                    <p className='one_p'>Rootswap is an Automated Market Maker (AMM)-based decentralized exchange for swapping NFTs with VE(3,3) token incentive model and token incentives based on trading volume.To put it simply, Rootswap is Sudoswap on steriods.</p>
                    <p style={{ height: "10px" }} />
                    <p>The Rootswap NFTs, or “Rootners”, is a collection of **** NFTs that help breathe life into Rootswap. The Rootswap AMM  will work in tandem with the NFTs to unlock the full value of your Rootners..In any AMM, governance and bootstrapping initial liquidity is essential. The function of the NFT minting will serve as the ticket for governance and drive the initial liquidity. Owning a Rootner also unlocks access to mainnet, special features, boosted rewards, exclusive events, participation in governance, and more.</p>
                    {/* <p>The structure of the mint will be the following:</p>
                    <p>The first 1,000 Rootners NFTs will be free to mint.</p>
                    <p>1,000 Rootners NFTs will be reserved for current and future partners</p>
                    <p>2,000 Rootners NFTs will be reserved for the team to provide initial liquidity</p>
                    <p>The remaining 6,000 Rootners NFTs can be minted at 0.08ETH</p> */}
                </div>
                <VideoWidget />
            </div>

        </MainStyle>
    )
}

const VideoWidget: React.FC = () => {
    return (
        <VideoStyle className='video_wap'>
            <div className="video_ref">
                <video style={{
                }} loop autoPlay muted playsInline>
                    <source src={rootswap_gif} />
                </video>
            </div>

        </VideoStyle>

    );
}

const VideoStyle = styled.div`
    display: flex;
    justify-content: end;
    position: absolute;
    z-index:-1;
    right: -120px;
    bottom: -50px;

    .video_ref{
        width: 760px;
        video{
            width: 100%;
        }
    }

    @media (max-width: 600px) {
      display: none;
    }
`

const MainStyle = styled.div`

    width: 100%;
    margin: 60px auto 130px auto;
    .logo{
        display: block;
        width: 200px;
    }
    .video_phone{
        display: none;
    }
    .inner{
        margin: 0 auto 100px auto;
        text-align: center;
        position: relative;
        .text{
            width: 63%;
            padding-top: 0;
            line-height: 1.4;
            font-size: 18px;
            text-align: start;
            font-family: zx;
            p{
                font-family:zx;
                line-height: 1;
                margin-bottom: 4px;
            }

        }
    } 

    @media (max-width: 600px) {
        margin-top: 20px;
        width: 100%;
        .video_phone{
            display: block;
            margin-bottom: 30px;
        }
        .inner{
            padding:0 10px;
            .text{
                position: relative;
                width: 100%;
                padding-top: 0;
                img{
                    display: block;
                    float: right;
                }
                .one_p{
                    width: 100% !important;
                }
            }
        }
    }

    @media (max-width: 900px) {
        margin-top: 20px;
        width: 100%;
        .inner{
            .text{
                width: 100%; 
                .one_p{
                    width: 50%;
                }
            }
            .video_wap{
                bottom: 100px; 
                top: 0px;
            }
            .video_ref{
            width: 560px;
            video{
                width: 100%;
            }
        }

        }
    }
`