import './App.css';
import { BannerWidget } from './components/banner';
import { FooterWidget } from './components/foot';
import { HeaderWidget } from './components/header';
import { MainWidget } from './components/main';
import { QAWidget } from './components/qa';
import { UtilityWidget } from './components/utility';
import { Element } from "react-scroll"
import { HashRouter as Router, Switch, Route, Redirect, useParams, } from "react-router-dom";
import { ProjectWidget } from './components/project';

const Page: React.FC = () => {
  let { project } = useParams<{ project: string }>();

  return (
    <div>

      <HeaderWidget />
      {project && <ProjectWidget />}
      {
        !project && <> <BannerWidget />
          <Element name="test1" className="element" />
          <MainWidget />
          <Element name="test2" className="element" />
          <UtilityWidget />
          <Element name="test3" className="element" />
          <QAWidget />
          <FooterWidget /></>
      }
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/main" component={Page} />
          <Route path="/:project" component={Page} />
          <Redirect to="/azuki" />
        </Switch>
      </Router>

    </div>
  );
}

export default App;
