

import { Grid, Tooltip } from "@material-ui/core";
import React, { PropsWithChildren } from "react"
import styled from "styled-components";
import bgImg from "../assets/images/azuki.jpeg"
export const ProjectWidget: React.FC<PropsWithChildren> = () => {

    return (
        <ProjectStyle>
            <div className="inner">
                <Grid className="top_wap" container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <div className="top">
                            <h3>5,555</h3>
                            <h4>Total Supply</h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="top">
                            <h3>0.042069</h3>
                            <h4>Whitelist Mint price</h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="top">
                            <h3>200</h3>
                            <h4>AZUKI Whitelist</h4>
                        </div>
                    </Grid>
                </Grid>

                <h4 className="title">Azuki PRE-WHITELIST</h4>

                <h5 className="msg">Anyone who has the <span>AZUKI</span> NFT will have exclusive access to the RootSwap NFT whitelist.
                    The <span>first 200</span> pre-registered whitelist spots are on a <span>FCFS</span> basis. Subsequent whitelisting spots will be chosen via <span>lottery</span>.

                </h5>
                <h6 className="tis">Multiple, random snapshots will be taken before official minting to avoid gaming the system.</h6>

                <Tooltip title="Coming Soon" arrow>
                    <div className="button">
                        PRE-register
                    </div>
                </Tooltip>
            </div>
        </ProjectStyle>
    )
}


const ProjectStyle = styled.div`
    width: 100%;
    display: flex;
    padding: 120px 0;
    /* margin-left:-13%; */
    
    
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
                 linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
                  url(${bgImg});
                  background-repeat: no-repeat;
    background-size: 100% 100% ;

    .inner{
        width:80%;
        margin:0 auto;
        .title{
            font-family: 'ZX';
            font-style: normal;
            font-weight: 400;
            font-size: 62px;
            line-height: 62px;
            text-align: center;
            color: #FFFFFF;
            margin:40px 0  30px 0;
        }
        .msg{
            font-family: 'ZX';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 32px;
            text-align: center;
            letter-spacing: -0.08em;
            color: #FFFFFF;
            span{
                font-family: 'ZX';
                color:#E0446A;
            }
        }
        .tis{
            font-family: 'ZX';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.08em;
            color: #FFFFFF;
            margin-top:20px ;
        }

        .button{
            margin-top: 50px;
            display: inline-block;
            height: 40px;   

            padding: 0 20px;
            border-radius: 10px;
            background-color: #fff;
            color: rgba(6, 54, 30, 1);
            line-height: 50px;
            cursor: pointer;
        }
        text-align:center ;
    }

    .top_wap{
            >div{
                padding: 20px;
                box-sizing: border-box;
                .top{
                    text-align:center;
                    background: rgba(0, 0, 0, 0.4);
                    border: 1px solid rgba(141, 144, 154, 0.4);
                    border-radius: 10px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding:14px 0;
                    height: 100%;
                    width:  100%;
                    box-sizing: border-box ;

                    h3{
                        font-family: 'Skinny';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 72px;
                        line-height: 90px;
                        text-align: center;
                        color: #FFFFFF;
                    }

                    h4{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 28px;
                        /* identical to box height */
                        font-family: 'ZX';
                        text-align: center;

                        color: #FFFFFF;
                    }
                }
            }
    }

    @media (max-width: 600px) {
        padding-top:60px ;
     .top_wap{
        >div{
           .top{
            h3{
                font-size: 52px; 
                line-height: 60px;
            }

           } 
        }
     }
     .inner{
        .title{
            font-size: 32px;
            line-height: 32px;
        }
        .msg{
            font-size: 24px;
        }
     }
    }

`