
import { Grid } from "@material-ui/core";
import React, { PropsWithChildren, ReactElement, ReactNode, useCallback } from "react"
import styled from "styled-components";
import logolImg from "../assets/images/root_log_l.png"
import logorImg from "../assets/images/root_log_r.png"
import { scroller } from "react-scroll";
export const HeaderWidget: React.FC<PropsWithChildren> = () => {
    const goToFn = useCallback((ev: string) => {
        scroller.scrollTo(ev, {
            duration: 500,
            delay: 100,
            smooth: 'easeInOutQuint',
        })
    }, [])
    return (
        <HeaderStyle className="app_inner">
            <Grid spacing={2} container justifyContent={'space-between'}>
                <Grid item sm={4} xs={12}>
                    <div className='logo'>
                        <img className='l' src={logolImg} alt="" />
                        <img className='r' src={logorImg} alt="" />
                    </div>
                </Grid>
                <Grid item sm={3} xs={12} justifyContent={"flex-end"}>
                    <div className='right'>
                        <p onClick={() => goToFn('test1')}>RootSwap</p>
                        <p onClick={() => goToFn('test2')}>Utility</p>
                        <p onClick={() => goToFn('test3')}>FAQs</p>
                    </div>
                </Grid>
            </Grid>


        </HeaderStyle>
    )
}


const HeaderStyle = styled.div`
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: space-between;

    .logo{
        align-items: center;
        justify-content: center;
        img{
            display: block;
        }
        .l{
            width: 71px;
        }

        .r{
            margin-left: 10px;
            width: 151px;
        }
        
    }

    .right{
        display: flex;

        width: 100%;
    }
    div{
        display: flex;
        align-items: center;

        p{
            font-size: 16px;
            margin-left: 50px;
            cursor: pointer;
        }
        p:first-child{
            margin-left: 0;
        }
    }

    @media (max-width: 600px) {
        .logo{width:100%}
        .right{
            justify-content: space-around;
        }
  }

`