
import { Backdrop, CircularProgress, Tooltip } from "@material-ui/core";
import React, { PropsWithChildren, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components";
import street_sign from "../assets/images/street_sign.png"
const horseMp4 = require("../assets/mp4/horse.mp4");

export const MainWidget: React.FC<PropsWithChildren> = () => {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <MainStyle  className="app_inner">
            <div className="text">
                <h2>Get a Spot on the Whitelist!</h2>
                <h3>Connect your Wallet and get a chance to get whitelisted for the minting event on Date TBA</h3>

                <Tooltip title="Coming Soon" arrow>
                    <div onClick={() => handleToggle()} className="button">
                        PRE-register
                    </div>
                </Tooltip>
                <div className='street_sign'>
                    <p className="title">Coming Soon</p>
                    <img src={street_sign} alt="" />
                </div>


            </div>

            <VideoWidget />
            <Backdrop
                style={{
                    zIndex: 99
                }}
                open={open}
                onClick={handleClose}
            >
                {/* <CircularProgress color="inherit" /> */}
                <ComingStyle>Coming Soon</ComingStyle>
            </Backdrop>
        </MainStyle>
    )
}

const VideoWidget: React.FC = () => {
    const listHeight: any = useRef()
    const [position, setPosition] = useState(0)

    const [transition, setTransition] = useState(4)

    const offsetFn = useCallback((width: number) => {
        setTransition(4)
        setPosition(width)
        setTimeout(() => {
            // setTransition(0)
            // setPosition(0)
            // offsetFn(width)
        }, 4000)
    }, [],)
    useEffect(() => {
        // offsetFn(listHeight.current.clientWidth)
    }, []);

    return (
        <div ref={listHeight} style={{ width: "100%" }}>
            <video style={{
                height: "106px",
                width: "96px",
                // transform: `translate(${position}px, 0)`,
                // transition: `transform ${transition}s`
                // left: (0 - postion.left) + "px",
                // top: (0 - postion.top) + "px"
            }} loop autoPlay muted playsInline>
                <source src={horseMp4} />
            </video>
        </div>

    );
}

const ComingStyle = styled.div`
font-family: zx;
font-size: 48px;
border-radius: 10px;
background-color: #8a623c;
padding: 30px;
`

const MainStyle = styled.div`
    margin: 107px 0 165px 0;
    position: relative;
    @media (max-width: 1000px) {
        margin-bottom: 50px;
    }
    .text{
        max-width: 752px;
        margin: 0 auto 100px auto;
        text-align: center;
        h2{
            font-size: 48px;
            text-align: center;
            margin-bottom: 29px;
        }

        h3{
            font-size: 32px;
            text-align: center;
        }

        .button{
            margin-top: 20px;
            display: inline-block;
            height: 40px;   
            padding: 0 20px;
            border-radius: 10px;
            background-color: #fff;
            color: rgba(6, 54, 30, 1);
            line-height: 50px;
            cursor: pointer;
        }

        img{
            display: block;
           
            
        }

        .street_sign{
            position: relative;
            width: 60%;
            margin: 78px auto 0 auto;
            .title{
                position: absolute;
                text-align: center;
                width: 100%;
                height: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 48px;

            }
            img{
                display: block;
                width: 100%;

            }
        }
    }

    video{
        animation-duration: 5s;
        animation-name: slidein;
        animation-iteration-count: infinite;
        /* transform: translate(1000px, 0); */
    }
    @keyframes slidein {
        from {
            transform: translate(0, 0);
        }

        to {
            transform: translate(1000px, 0);
        }  
        
         }

    
    @media (max-width: 600px) {
        margin: 107px 0 105px 0;
        video{
            display: none;
        }
       .text{
        h2{
            font-size: 34px;
        }
        h3{
            font-size: 24px;
        }
        .street_sign{
            .title{
                font-size: 28px;
            }
        }

        video{
        animation-duration: 5s;
        animation-name: slidein;
        animation-iteration-count: infinite;

        /* transform: translate(1000px, 0); */
    }
    @keyframes slidein {
        from {
            transform: translate(0, 0);
        }

        to {
            transform: translate(500px, 0);
        }
    }

       } 
}
`